import type { Dispatch, FC, SetStateAction } from "react";

import CloseIcon from "@mui/icons-material/Close";

import { Content, StyledModal } from "./style";

const TermsModal: FC<{
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	cryptedProductTypeId: string | false;
	cryptedProductId: string | false;
}> = ({ open, setOpen, cryptedProductTypeId, cryptedProductId }) => {
	let src = "Termos e Condições.pdf";

	if (
		cryptedProductTypeId &&
		cryptedProductTypeId === "c5d88545973bb9108c108b72ab918570"
	) {
		src =
			"https://igtieadstorage.blob.core.windows.net/pdfs/[Regulamento]%20TRIAL%20e%20BOOTCAMP%20PASS%2005_2024.docx.pdf";
	} else if (
		cryptedProductTypeId &&
		cryptedProductTypeId === "e3aa9678236a8e7da440ed7cd1051f10"
	) {
		src =
			"https://igtieadstorage.blob.core.windows.net/pdfs/[CLP (Mercado Financeiro)] Termo Checkout.pdf";
	} else if (
		cryptedProductId &&
		cryptedProductId === "08a78b32f4a3dc4be80f08a180a4cd65"
	) {

		src = "https://igtieadstorage.blob.core.windows.net/pdfs/Regulamento%20-%20Minicamp%20IA.pdf";
	} else if (
		cryptedProductTypeId &&
		cryptedProductTypeId === "a7cf7773c5a25c1912351bcad4b9978d"
	) {
		src =
			"https://igtieadstorage.blob.core.windows.net/pdfs/Regulamento%20Cursos%20Livres.pdf";
	} else if (
		cryptedProductTypeId &&
		cryptedProductTypeId === "166900a5627a9e996fb54f685bf6592a"
	) {
		src =
			"https://igtieadstorage.blob.core.windows.net/pdfs/Termos%20de%20Uso%20-%20Cursos%20Gratuitos.pdf";
	} else if (
		cryptedProductTypeId &&
		cryptedProductTypeId === "0b6ddc573791c362da349af9e857f5eb"
	) {
		src =
			"https://igtieadstorage.blob.core.windows.net/pdfs/REGULAMENTO_ACX.pdf";
	}

	return (
		<StyledModal open={open} onClose={() => setOpen(false)}>
			<Content>
				<button onClick={() => setOpen(false)}>
					<CloseIcon />
				</button>

				<iframe id="frame" title="Termos e Condições" src={src} />
			</Content>
		</StyledModal>
	);
};

export default TermsModal;
