import { type FC, useEffect, useState } from "react";

import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import CustomLoader from "src/shared/components/general/Loader";

import { usePayment } from "src/shared/contexts/Payment";
import { useProduct } from "src/shared/contexts/Product";

import Button from "@mui/material/Button";
import { baseAPI, baseHeaders } from "src/shared/config/axios";
import { usePerson } from "src/shared/contexts/Person";
import { useToast } from "src/shared/contexts/Toast";
import { PaymentTypeContainer, StyledCard, StyledItemsList, StyledValuesList } from "./style";

const Abstract: FC = () => {
	const { productTypes, products, acquiredProducts, setAcquiredProducts } = useProduct();
	const { paymentInfo, setPaymentInfo } = usePayment();
	const { personInfo } = usePerson();
	const [totalValue, setTotalValue] = useState(0);
	const [enrollmentValue, setEnrollmentValue] = useState(0);
	const [totalDiscountValue, setTotalDiscountValue] = useState(0);
	const [urlProductName, setUrlProductName] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [updatedProductValue, setUpdatedProductValue] = useState<number | null>(null);

	const productWithoutPayment = (totalValue > 0 && totalDiscountValue === totalValue) || totalValue == 0;
	const { addToast } = useToast();

	useEffect(() => {
		const productName = Object.keys(products).map(() => {
			return new URL(window.location.href).searchParams.get("igti_checkout_products") ?? "";
		});

		if (productName[0]?.length > 0) {
			setIsLoading(true);
		}

		setUrlProductName(productName[0]);
	}, [products, setUrlProductName]);

	useEffect(() => {
		const fetchUpdatedProduct = async () => {
			const lead = sessionStorage.getItem("@checkout_lead@");
			if (!lead) return;

			try {
				const {
					data: { success, response },
				} = await baseAPI.put(
					`/leads/${lead}/`,
					{
						product_list: acquiredProducts.map(({ crypted_id }) => crypted_id),
						product_name: acquiredProducts[0].nome,
					},
					{ headers: baseHeaders() },
				);

				if (response.updated_product) {
					setAcquiredProducts((prevProducts) =>
						prevProducts.map((product) =>
							product.crypted_id === response.updated_product.crypted_id
								? {
										...product,
										valor: response.updated_product.valor,
										valor_descontado: response.updated_product.valor_descontado,
										valor_descontado_original: response.updated_product.valor_descontado_original,
									}
								: product,
						),
					);

					setPaymentInfo((prev) => {
						return {
							...prev,
							discounts: [],
						};
					});
				}
			} catch (error) {
				console.error("Erro ao buscar produto atualizado:", error);
			}
		};

		fetchUpdatedProduct();
	}, [personInfo.b2bCompany]);

	useEffect(() => {
		setTotalValue(acquiredProducts.reduce((prev, { valor }) => (prev += valor), 0));
		setIsLoading(false);
	}, [acquiredProducts]);

	useEffect(() => {
		const { value: newEnrollmentValue } = paymentInfo.additionals.find(
			({ name }) => name === "Taxa(s) de matrícula",
		) ?? { value: 0 };

		setEnrollmentValue(newEnrollmentValue);
	}, [paymentInfo.additionals]);

	useEffect(() => {
		const totalValue = acquiredProducts.reduce((prev, { valor }) => (prev += valor), 0);

		const { payment_discount } =
			Object.keys(productTypes).length > 0 && acquiredProducts.length > 0
				? (acquiredProducts[0].payment_rules ??
					productTypes.find(({ crypted_id }) => crypted_id === acquiredProducts[0].crypted_product_type_id)!)
				: { payment_discount: [] };

		let discount: any, discount_exception: any;
		const paymentRule = payment_discount?.find(({ payment_type }) => payment_type === paymentInfo.payment_type);
		if (paymentRule && !personInfo.b2bCompany) {
			discount = paymentRule.discount;
			discount_exception = paymentRule.discount_exception;
		} else {
			discount = 0;
			discount_exception = [];
		}

		const matchingDiscount = discount_exception
			? discount_exception.find((item: any) => item.installment === paymentInfo.installments)
			: null;

		const earlyBuyDiscountValue = matchingDiscount
			? totalValue * (matchingDiscount.discount / 100)
			: totalValue * (discount / 100);

		// Check if the discount name is "100% de desconto"
		const is100PercentDiscount = paymentInfo.discounts?.some((discount) => discount.name === "100% de desconto");

		if (is100PercentDiscount) {
			// Set total discount value to match total value for 100% discount
			setTotalDiscountValue(totalValue);
			// Update payment info to reflect zero payment
			setPaymentInfo((prev) => ({
				...prev,
				value: 0,
				discounts: [...prev.discounts.filter(({ name }) => name === "100% de desconto")],
				couponValue: totalValue,
				additionals: [],
			}));
		} else if (personInfo.b2bCompany) {
			setTotalDiscountValue(0);
		} else if (paymentInfo.generalist_voucher) {
			setTotalDiscountValue(paymentInfo.couponValue ?? 0);
		} else {
			setTotalDiscountValue(earlyBuyDiscountValue + (paymentInfo.couponValue ?? 0));
		}
	}, [acquiredProducts, productTypes, paymentInfo, personInfo.b2bCompany, setPaymentInfo]);

	useEffect(() => {
		const multipleItemsDiscount = acquiredProducts.reduce((prev, { valor, valor_descontado_original }) => {
			prev += valor_descontado_original ? valor - valor_descontado_original : 0;
			return prev;
		}, 0);

		if (multipleItemsDiscount > 0) {
			setPaymentInfo((prev) => {
				return {
					...prev,
					discounts: [
						...prev.discounts.filter(({ name }) => name !== "Múltiplos itens"),
						{ name: "Múltiplos itens", value: multipleItemsDiscount },
					],
				};
			});
		} else {
			setPaymentInfo((prev) => {
				return {
					...prev,
					discounts: prev.discounts.filter(({ name }) => name !== "Múltiplos itens"),
				};
			});
		}

		if (acquiredProducts.length === 0) {
			setPaymentInfo((prev) => {
				return {
					...prev,
					discounts: prev.discounts.filter(({ name }) => name !== "Matrícula Antecipada"),
				};
			});
		}
	}, [acquiredProducts, setPaymentInfo, setAcquiredProducts]);

	const handleSubmitAccordion = async () => {
		setIsSuccess(false);
		setIsLoading(true);

		const lead = sessionStorage.getItem("@checkout_lead@");

		if (!lead) {
			addToast({
				type: "info",
				title: "Informações incompletas",
				description: "Insira e confirme suas informações pessoais para efetuar o pagamento",
			});

			setIsLoading(false);
		} else {
			try {
				const {
					data: { success, response },
				} = await baseAPI.post(
					`/leads/${lead}/payments/finish`,
					{},
					{
						headers: baseHeaders(),
					},
				);

				if (success) {
					addToast({
						type: "success",
						title: "Compra confirmada",
						description:
							"A compra foi efetuada com sucesso. Verifique no seu e-mail as instruções para os próximos passos",
					});
					setIsSuccess(true);
					window.location.replace(`https://checkout.xpeducacao.com.br/confirmacao-compra/`);
				} else {
					addToast({
						type: "error",
						title: "Erro ao confirmar compra",
						description: "Por favor, revise suas informações ou entre em contato com o nosso suporte.",
					});
				}

				setIsLoading(false);
			} catch (error) {
				addToast({
					type: "error",
					title: "Erro ao confirmar compra",
					description: "Por favor, revise suas informações ou entre em contato com o nosso suporte.",
				});
				setIsLoading(false);
			}
		}
	};

	return (
		<StyledCard>
			<CardContent>
				<Typography variant="h6" fontWeight={700} sx={{ marginBottom: "1rem" }}>
					Resumo
				</Typography>

				{isLoading ? (
					<CustomLoader />
				) : (
					<StyledItemsList>
						{acquiredProducts.map(({ crypted_id, nome, nome_exibicao, link_imagem }) => (
							<li key={crypted_id}>
								<img src={link_imagem ?? "/placeholder-icon.png"} alt="Ícone do produto" width={75} />
								<Typography color="GrayText" fontWeight={700}>
									{urlProductName.length > 0 &&
									nome_exibicao?.toLowerCase().includes("semestral") &&
									urlProductName?.toLowerCase().includes("semestral") &&
									urlProductName !== nome_exibicao
										? urlProductName
										: urlProductName.length > 0 &&
												!nome_exibicao?.toLowerCase().includes("bootcamp") &&
												!nome_exibicao?.toLowerCase().includes("assinatura")
											? `${nome_exibicao?.length > 0 ? nome_exibicao : nome}`
											: nome_exibicao?.length > 0
												? nome_exibicao
												: nome}
								</Typography>
							</li>
						))}
					</StyledItemsList>
				)}

				<StyledValuesList>
					<li>
						<Typography color="GrayText">Subtotal</Typography>
						<Typography color="GrayText">
							{new Intl.NumberFormat("pt-BR", {
								style: "currency",
								currency: "BRL",
							}).format(totalValue)}
						</Typography>
					</li>

					{paymentInfo.additionals &&
						paymentInfo.additionals.length > 0 &&
						paymentInfo.additionals.map(
							({ name, value }, index) =>
								value !== 0 && (
									<li key={index}>
										<Typography color="GrayText">{name}</Typography>
										<Typography color="GrayText">
											{new Intl.NumberFormat("pt-BR", {
												style: "currency",
												currency: "BRL",
											}).format(value)}
										</Typography>
									</li>
								),
						)}

					{paymentInfo.discounts &&
						paymentInfo.discounts.length > 0 &&
						paymentInfo.discounts.map(
							({ name, value }, index) =>
								value !== 0 && (
									<li key={index}>
										<Typography color="GrayText">{name}</Typography>
										<Typography color="green">
											-{" "}
											{new Intl.NumberFormat("pt-BR", {
												style: "currency",
												currency: "BRL",
											}).format(value)}
										</Typography>
									</li>
								),
						)}

					<li style={{ marginTop: "1rem" }}>
						<Typography fontWeight={700}>Total a pagar</Typography>
						<Typography fontWeight={700} fontSize={18}>
							{new Intl.NumberFormat("pt-BR", {
								style: "currency",
								currency: "BRL",
							}).format(updatedProductValue ?? paymentInfo.value)}
						</Typography>
					</li>
				</StyledValuesList>

				{paymentInfo.payment_type !== "-" && (
					<div>
						<Divider sx={{ margin: "1rem 0" }} />

						{productWithoutPayment ? (
							<Button
								onClick={handleSubmitAccordion}
								id="paymentInfoSubmitButton"
								type="submit"
								disabled={isSuccess}
								variant="contained"
								color="success"
								sx={{ width: "100%", marginTop: "1rem" }}
							>
								{isLoading ? <CustomLoader /> : <span>Confirmar Compra</span>}
							</Button>
						) : (
							<PaymentTypeContainer>
								<Typography color="GrayText" fontWeight={700}>
									Forma de Pagamento
								</Typography>

								<div>
									<div>
										{paymentInfo.payment_type && paymentInfo.payment_type !== "-"
											? paymentInfo.payment_type.charAt(0).toUpperCase() +
												paymentInfo.payment_type.split("_")[0].slice(1)
											: "-"}
									</div>

									<div>
										{paymentInfo.payment_type === "recorrente" ? (
											paymentInfo.installments > 1 ? (
												<>
													1ª de{" "}
													{new Intl.NumberFormat("pt-BR", {
														style: "currency",
														currency: "BRL",
													}).format(
														(totalValue - totalDiscountValue + (paymentInfo.fee || 0)) / paymentInfo.installments +
															enrollmentValue,
													)}
													<br />
													{paymentInfo.installments - 1}x de{" "}
													{new Intl.NumberFormat("pt-BR", {
														style: "currency",
														currency: "BRL",
													}).format(
														(totalValue - totalDiscountValue + (paymentInfo.fee || 0)) / paymentInfo.installments,
													)}
												</>
											) : (
												<>
													1x de{" "}
													{new Intl.NumberFormat("pt-BR", {
														style: "currency",
														currency: "BRL",
													}).format(
														(totalValue - totalDiscountValue + enrollmentValue + (paymentInfo.fee || 0)) /
															paymentInfo.installments,
													)}
												</>
											)
										) : paymentInfo.payment_type === "boleto_parcelado" ? (
											<>
												{paymentInfo.installments}x de{" "}
												{new Intl.NumberFormat("pt-BR", {
													style: "currency",
													currency: "BRL",
												}).format(
													(totalValue - totalDiscountValue + enrollmentValue + (paymentInfo.fee || 0)) /
														paymentInfo.installments,
												)}
											</>
										) : (
											<>
												{paymentInfo.installments}x de{" "}
												{new Intl.NumberFormat("pt-BR", {
													style: "currency",
													currency: "BRL",
												}).format((totalValue - totalDiscountValue + enrollmentValue) / paymentInfo.installments)}
											</>
										)}
									</div>
								</div>
							</PaymentTypeContainer>
						)}
					</div>
				)}
			</CardContent>
		</StyledCard>
	);
};

export default Abstract;
