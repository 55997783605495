import { useCallback, useEffect, useState, type FC } from "react";

import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { baseAPI, baseHeaders } from "src/shared/config/axios";

import { usePayment } from "src/shared/contexts/Payment";
import { useProduct } from "src/shared/contexts/Product";
import { useToast } from "src/shared/contexts/Toast";

import type { IAccordionEntry } from "src/shared/interfaces/accordions";

import CustomLoader from "src/shared/components/general/Loader";

import { StyledCard } from "./style";

const Abstract: FC<{ accordions: IAccordionEntry[] }> = ({ accordions }) => {
	/**Variáveis que mantém o(s) objeto(s) do(s) produto(s) sendo adquirido(s) pelo usuário */
	const { acquiredProducts, setAcquiredProducts } = useProduct();
	/**Variável que mantém as informações de pagamento do usuário */
	const { paymentInfo, setPaymentInfo } = usePayment();
	/**Função que realiza a inserção de toasts informativos */
	const { addToast } = useToast();

	/**Flag que controla o carregamento da operação de validação do cupom de desconto */
	const [isLoading, setIsLoading] = useState(false);
	/**Variável que mantém o cupom atualmente inserido */
	const [coupon, setCoupon] = useState(() => new URL(window.location.href).searchParams.get("coupon") || "");
	/**Flag que controla se o cupom foi validado com sucesso */
	const [couponValidated, setCouponValidated] = useState(false);

	/**Função responsável por validar o cupom inserido pelo usuário
	 * @param coupon cupom inserido pelo usuário
	 */
	const handleValidateCoupon = useCallback(async () => {
		const lead = sessionStorage.getItem("@checkout_lead@");

		if (!lead) {
			addToast({
				type: "info",
				title: "Informações incompletas",
				description: "Insira e confirme suas informações pessoais para validar um cupom",
			});

			return;
		}

		/**Caso o usuário tenha retirado o cupom */
		if (coupon === "") {
			setPaymentInfo((prev) => {
				return {
					...prev,
					coupon,
					couponValue: 0,
					discounts: [
						...(prev.discounts ? [...prev.discounts.filter(({ name }) => name !== "Cupom de desconto")] : []),
					],
					generalist_voucher: false,
				};
			});

			return;
		}

		/**Sempre resetar informações do cupom (sem return) */
		setPaymentInfo((prev) => {
			return {
				...prev,
				coupon,
				couponValue: 0,
				discounts: [...(prev.discounts ? [...prev.discounts.filter(({ name }) => name !== "Cupom de desconto")] : [])],
				generalist_voucher: false,
			};
		});

		try {
			setIsLoading(true);

			const {
				data: { response, success },
			} = await baseAPI.post<{
				response: {
					discount: { type: string; value: number };
					generalist_voucher: boolean;
					product_list: { applicable: boolean; crypted_id: string }[];
					valid: boolean;
				};
				success: string;
			}>(
				`/leads/${lead}/validate_vouchers/`,
				{
					voucher_code: coupon.trim(),
					product_list: acquiredProducts.map(({ crypted_id }) => crypted_id),
					payment_type: paymentInfo.payment_type,
				},
				{ headers: baseHeaders() },
			);

			if (success === "false")
				addToast({
					type: "error",
					title: "Erro na validação",
					description: "Ocorreu um erro na validação do cupom, tente novamente.",
				});
			else if (!response.valid)
				addToast({
					type: "error",
					title: "Cupom inválido",
					description: "O cupom inserido não existe ou não é válido",
				});
			else {
				/**Verifica se o(s) produto(s) selecionado(s) é(são) elegível(is) a receber(em) o desconto do cupom */
				const applicableProduct = response.product_list.find(({ applicable }: { applicable: boolean }) => applicable);

				if (!applicableProduct)
					addToast({
						type: "error",
						title: "Erro na validação",
						description: "O cupom inserido não é aplicável ao(s) produto(s) selecionado(s).",
					});
				else {
					const discountableValue =
						acquiredProducts.reduce((prev, { valor }) => (prev += valor), 0) +
						paymentInfo.additionals
							.filter(({ name }) => name !== "Taxa(s) de matrícula")
							.reduce((prev, { value }) => (prev += value), 0) -
						paymentInfo.discounts
							.filter(({ name }) => name !== "Cupom de desconto")
							.reduce((prev, { value }) => (prev += value), 0);

					const discountableValueWithoutDiscounts = acquiredProducts.reduce((accumulator, { valor_descontado }) => {
						return accumulator + valor_descontado;
					}, 0);

					/**Valor total descontado pelo cupom */
					const couponDiscountValue =
						response.discount.type === "PERCENT"
							? response.generalist_voucher
								? discountableValueWithoutDiscounts * (response.discount.value / 100)
								: (discountableValue - (paymentInfo.fee || 0)) * (response.discount.value / 100)
							: response.discount.value / 100;

					const totalOff = response.discount.type === "PERCENT" && response.discount.value === 100;

					if (totalOff) {
						setPaymentInfo((prev) => {
							return {
								...prev,
								discounts: [
									...(prev.discounts ? [...prev.discounts.filter(({ name }) => name !== "Cupom de desconto")] : []),
									{
										name: "100% de desconto",
										value: acquiredProducts.reduce((accumulator, { valor }) => {
											return accumulator + valor;
										}, 0),
									},
								],
								additionals: [],
							};
						});
					} else {
						setPaymentInfo((prev) => {
							return {
								...prev,
								coupon,
								couponValue: couponDiscountValue,
								discounts: [
									...(prev.discounts ? [...prev.discounts.filter(({ name }) => name !== "Cupom de desconto")] : []),
									{
										name: "Cupom de desconto",
										value: couponDiscountValue - (paymentInfo.fee || 0),
									},
								],
								generalist_voucher: response.generalist_voucher,
							};
						});
					}

					setCouponValidated(true);
					addToast({
						type: "success",
						title: "Cupom aplicado",
						description: "O cupom foi aplicado com sucesso",
					});
				}
			}
		} catch {
			addToast({
				type: "error",
				title: "Falha ao validar cupom",
				description: "Ocorreu um erro ao tentar validar o cupom, tente novamente",
			});
		} finally {
			setIsLoading(false);
		}
	}, [addToast, acquiredProducts, paymentInfo, setPaymentInfo, coupon]);

	/**Efeito que verifica a aplicação de desconto por aquisição de múltiplos itens, quando aplicável */
	useEffect(() => {
		const multipleItemsDiscount = acquiredProducts.reduce((prev, { valor, valor_descontado_original }) => {
			prev += valor_descontado_original ? valor - valor_descontado_original : 0;
			return prev;
		}, 0);

		if (multipleItemsDiscount > 0) {
			setPaymentInfo((prev) => {
				return {
					...prev,
					discounts: [
						...prev.discounts.filter(({ name }) => name !== "Múltiplos itens"),
						{ name: "Múltiplos itens", value: multipleItemsDiscount },
					],
				};
			});
		} else {
			setPaymentInfo((prev) => {
				return {
					...prev,
					discounts: prev.discounts.filter(({ name }) => name !== "Múltiplos itens"),
				};
			});
		}

		if (acquiredProducts.length === 0) {
			setPaymentInfo((prev) => {
				return {
					...prev,
					discounts: prev.discounts.filter(({ name }) => name !== "Matrícula Antecipada"),
				};
			});
		}
	}, [acquiredProducts, setPaymentInfo, setAcquiredProducts]);

	/**Efeito que retira o cupom caso alguma modificação no pagamento possa invalidá-lo */
	useEffect(() => {
		paymentInfo.coupon === "" && setCoupon("");
	}, [paymentInfo.coupon]);

	/**Efeito que valida automaticamente o cupom */
	useEffect(() => {
		if (!accordions[accordions.length - 1].disabled) handleValidateCoupon();
	}, [accordions[accordions.length - 1].disabled]);

	return (
		<StyledCard>
			<CardContent>
				<Typography variant="h6" fontWeight={700} sx={{ marginBottom: "1rem" }}>
					Cupom
				</Typography>

				<div className="couponContainer">
					<TextField
						id="coupon"
						label="Informe o código"
						value={coupon}
						disabled={accordions[accordions.length - 1].disabled || couponValidated}
						onChange={({ target: { value } }) => setCoupon(value)}
					/>

					<Button
						variant="outlined"
						color="success"
						disabled={accordions[accordions.length - 1].disabled}
						onClick={handleValidateCoupon}
					>
						{isLoading ? <CustomLoader /> : <span>Aplicar</span>}
					</Button>
				</div>
			</CardContent>
		</StyledCard>
	);
};

export default Abstract;
