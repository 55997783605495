import { FC, FormEvent, useCallback, useEffect, useState } from "react";

import axios from "axios";

import ArrowForward from "@mui/icons-material/ArrowForward";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import CustomLoader from "src/shared/components/general/Loader";

import { baseAPI, baseHeaders } from "src/shared/config/axios";

import { usePerson } from "src/shared/contexts/Person";
import { useProduct } from "src/shared/contexts/Product";
import { useToast } from "src/shared/contexts/Toast";

import IAccordion from "src/shared/interfaces/accordions";

import CustomAccordion from "src/shared/components/general/Accordion";

import {
  InputItem,
  InputList,
} from "src/shared/styles/accordions/accordion.style";

const AddressInfoAccordion: FC<IAccordion> = ({
  id,
  disabled,
  expanded,
  accordions,
  setAccordions,
  setActiveStep,
}) => {
  /**Função de adição de um toast informativo */
  const { addToast } = useToast();
  /**Variável que mantém as informações do usuário */
  const { personInfo, setPersonInfo } = usePerson();
  /**Variável que mantém as informações dos produtos */
  const { acquiredProducts } = useProduct();

  /**Flag que controla se as operações de submissão do accordion estão ocorrendo */
  const [isLoading, setIsLoading] = useState(false);

  /** Flag que determina validade do CEP */
  const [isValidCep, setIsValidCep] = useState(false);

  /**Função responsável por preencher os inputs de endereço, após preenchimento do input de CEP, utilizando-se api da viacep */
  const handleGetAddress = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `https://viacep.com.br/ws/${personInfo["cep"]}/json/`
      );

      if (data.erro) {
        addToast({
          type: "error",
          title: "CEP inválido",
          description: "O CEP informado não existe",
        });

        return;
      }

      setPersonInfo((prev) => {
        return {
          ...prev,
          state: data.uf,
          city: data.localidade,
          neighborhood: data.bairro,
          street: data.logradouro,
        };
      });

      setIsValidCep(true);
    } catch (e) {
      addToast({
        type: "error",
        title: "CEP inválido",
        description: "O CEP informado não retorna nenhum endereço",
      });
    }
  }, [personInfo, setPersonInfo, addToast]);

  /**Função responsável por validar todos os campos e submeter o accordion */
  const handleSubmitAccordion = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      /**Verifica a presença de inputs inválidos, ou de um cpf incorreto */
      if (document.querySelectorAll(".addressInfoInput.invalid").length === 0) {
        setIsLoading(true);

        try {
          /**Verifica a existência de uma pessoa cadastrada com o cpf informado */
          const {
            data: { response },
          } = await baseAPI.post(`/persons/by_cpf/`,
            {
              cpf: personInfo["cpf"],
            },
            {
              headers: baseHeaders(),
            });

          let person_id = "";
          let b2b = "";

          /**Caso a pessoa não exista, cadastra a mesma */
          if (!response) {
            const { ...inputs } = personInfo;

            /**Cadastra a pessoa no banco, e recupera o id criado */
            const {
              data: {
                response: { crypted_id, b2b_company_name },
              },
            } = await baseAPI.post(
              `/persons/`,
              {
                ...inputs,
                number: Number(inputs["number"]),
              },
              { headers: baseHeaders() }
            );

            person_id = crypted_id;
            b2b = b2b_company_name;
          } else {
            person_id = response.crypted_id;
            b2b = response.b2b;
          }

          /**Atualiza as informações do usuário no Principia (empresa responsável pela funcionalidade de boleto parcelado) */
          const {
            birthDate,
            cep,
            state,
            city,
            neighborhood,
            street,
            number,
            complement,
          } = personInfo;
          await baseAPI.put(
            `/persons/${person_id}/boleto_parcelado/`,
            {
              birthday: birthDate,
            },
            {
              headers: baseHeaders(),
            }
          );
          await baseAPI.post(
            `/persons/${person_id}/address/`,
            {
              cep,
              state,
              city,
              neighborhood,
              street,
              number,
              complement,
            },
            {
              headers: baseHeaders(),
            }
          );

          /**Variavel para requisição de criação de lead */
          const leadCreationObject: {
            person_id: string;
            mql_email?: string;
            product_name?: string;
            utm_source?: string | null;
            utm_medium?: string | null;
            utm_campaign?: string | null;
            utm_content?: string | null;
            utm_term?: string | null;
            affiliate_id?: string;
            banner_id?: string;
          } = {
            person_id,
            mql_email: personInfo.email,
            utm_source: personInfo.utmSource,
            utm_medium: personInfo.utmMedium,
            utm_campaign: personInfo.utmCampaign,
            utm_content: personInfo.utmContent,
            utm_term: personInfo.utmTerm,
          };

          if (acquiredProducts && acquiredProducts.length > 0) {
            leadCreationObject["product_name"] =
              acquiredProducts[0].nome_exibicao || acquiredProducts[0].nome;
          }

          // Obter cookie
          const cookiesArray = document.cookie.split(";");

          for (let i = 0; i < cookiesArray.length; i++) {
            let cookie = cookiesArray[i].trim();

            if (cookie.indexOf("mgm_pap=") === 0) {
              const mgmCookie = cookie.substring("mgm_pap=".length);

              if (mgmCookie) {
                const cookieValues = mgmCookie.split("&");
                let affiliateId = null;
                let bannerId = null;

                cookieValues.forEach((pair) => {
                  const [key, value] = pair.split("=");
                  if (key === "a_aid") affiliateId = value;
                  if (key === "a_bid") bannerId = value;
                });

                if (affiliateId) leadCreationObject.affiliate_id = affiliateId;
                if (bannerId) leadCreationObject.banner_id = bannerId;
              }
            }
          }

          /**Gera o lead a ser utilizado pelo usuário */
          const {
            data: {
              response: { crypted_id: lead },
            },
          } = await baseAPI.post(`/leads/`, leadCreationObject, {
            headers: baseHeaders(),
          });

          /**Caso o usuário esteja atualizando suas informações pessoais após confirmar o produto, é necessário atualizar o lead */
          if (acquiredProducts && acquiredProducts.length > 0) {
            await baseAPI.put(
              `/leads/${lead}/`,
              {
                product_list: acquiredProducts.map(
                  ({ crypted_id }) => crypted_id
                ),
                product_name: acquiredProducts[0].nome,
              },
              { headers: baseHeaders() }
            );
          }

          /**Grava o lead em sessionStorage */
          sessionStorage.setItem("@checkout_lead@", lead);

          /**Insere as informações do usuário no respectivo contexto */
          setPersonInfo((prev) => {
            return { ...prev, id: person_id, b2bCompany: b2b };
          });

          /**Marca a flag, indicando que o accordion foi corretamente validado */
          setAccordions((prev) =>
            prev.map((acc) =>
              acc.id === id
                ? {
                    ...acc,
                    group: 1,
                    completed: true,
                    disabled: true,
                    completedInfo: {
                      ...acc.completedInfo,
                      content: [
                        `${personInfo.street}, ${personInfo.number}`,
                        `${personInfo.neighborhood}, ${personInfo.city} / ${personInfo.state}`,
                      ],
                    },
                  }
                : prev.find(
                    ({ id: prev_id, disabled }) => prev_id !== id && !disabled
                  )
                ? { ...acc }
                : acc.id === id + 2
                ? prev.find(({ id: prev_id }) => prev_id === id + 1)!.completed
                  ? { ...acc, disabled: false, expanded: true }
                  : { ...acc }
                : acc.id === id + 1
                ? prev.find(({ id: prev_id }) => prev_id === id + 1)!.completed
                  ? { ...acc, group: 1 }
                  : { ...acc, disabled: false, expanded: true }
                : { ...acc }
            )
          );

          addToast({
            type: "success",
            title: "Informações confirmadas",
            description:
              "As informações de endereço foram validadas e registradas com sucesso",
          });

          if (b2b) {
            addToast({
              title: "Parceiro B2B - " + b2b,
              description:
                "Bem-vindo(a) à Faculdade XP, colaborador(a) " + b2b + "!",
              type: "success",
            });
          }
        } catch (e) {
          console.log(e);

          addToast({
            type: "error",
            title: "Erro ao confirmar informações",
            description:
              "Ocorreu um erro ao processar a solicitação. Recarregue a página e tente novamente.",
          });
        }
      } else {
        setAccordions((prev) =>
          prev.map((acc) =>
            acc.id === id ? { ...acc, completed: false } : { ...acc }
          )
        );

        addToast({
          type: "info",
          title: "Informações faltantes",
          description: "Preencha os campos corretamente",
        });
      }

      setIsLoading(false);
    },
    [id, acquiredProducts, setAccordions, personInfo, setPersonInfo, addToast]
  );

  /**Efeito que atualiza a variável que mantém o passo atual do usuário, quando o accordion for validado */
  useEffect(() => {
    setActiveStep(accordions!.findIndex(({ disabled }) => !disabled));
  }, [accordions, setActiveStep]);

  return (
    <CustomAccordion
      id={id}
      title="Endereço"
      disabled={disabled}
      expanded={expanded}
      setAccordions={setAccordions}
    >
      <form onSubmit={handleSubmitAccordion}>
        <InputList>
          <InputItem sizes={[1]}>
            <TextField
              id="cep"
              className={`${
                personInfo.cep.length < 8 ? "invalid" : ""
              } addressInfoInput`}
              label="CEP (somente números)"
              variant="outlined"
              inputProps={{ minLength: 8, maxLength: 8 }}
              value={personInfo["cep"] || ""}
              error={personInfo.cep.length === 8 && !isValidCep}
              // helperText={personInfo.cep.length === 8 && !isValidCep && 'CEP inválido'}
              required
              onChange={({ target: { value } }) => {
                value = value.replace(/\D/g, "");

                setPersonInfo((prev) => {
                  return {
                    ...prev,
                    state: "",
                    city: "",
                    cep: value,
                  };
                });
              }}
              onBlur={() => personInfo.cep.length === 8 && handleGetAddress()}
            />
          </InputItem>

          <InputItem sizes={[1, 0.25]}>
            <TextField
              id="city"
              className={`addressInfoInput`}
              label="Cidade"
              variant="outlined"
              value={personInfo["city"] || ""}
              disabled
            />

            <TextField
              id="state"
              className={`addressInfoInput`}
              label="UF"
              variant="outlined"
              value={personInfo["state"] || ""}
              disabled
            />
          </InputItem>

          <InputItem sizes={[1]}>
            <TextField
              id="neighborhood"
              className={`${
                personInfo.neighborhood.length < 1 ? "invalid" : ""
              } addressInfoInput`}
              label="Bairro"
              variant="outlined"
              inputProps={{ maxLength: 40 }}
              value={personInfo["neighborhood"] || ""}
              required
              onChange={({ target: { value } }) =>
                setPersonInfo((prev) => {
                  return { ...prev, neighborhood: value };
                })
              }
            />
          </InputItem>

          <InputItem sizes={[1]}>
            <TextField
              id="street"
              className={`${
                personInfo.street.length < 1 ? "invalid" : ""
              } addressInfoInput`}
              label="Logradouro"
              variant="outlined"
              value={personInfo["street"] || ""}
              required
              onChange={({ target: { value } }) =>
                setPersonInfo((prev) => {
                  return { ...prev, street: value };
                })
              }
            />
          </InputItem>

          <InputItem sizes={[0.4, 1]}>
            <TextField
              id="addressNumber"
              className={`${
                personInfo.number.length < 1 ? "invalid" : ""
              } addressInfoInput`}
              type="number"
              label="Nº"
              variant="outlined"
              value={personInfo["number"] || ""}
              inputProps={{ min: 0 }}
              required
              onChange={({ target: { value } }) =>
                setPersonInfo((prev) => {
                  return { ...prev, number: value };
                })
              }
            />

            <TextField
              id="complement"
              className={`addressInfoInput`}
              label="Complemento"
              variant="outlined"
              value={personInfo["complement"] || ""}
              onChange={({ target: { value } }) =>
                setPersonInfo((prev) => {
                  return { ...prev, complement: value };
                })
              }
            />
          </InputItem>
        </InputList>

        <Button
          id="addressInfoSubmitButton"
          type="submit"
          variant="contained"
          color="success"
          endIcon={<ArrowForward />}
          disabled={
            personInfo.cep.length < 8 ||
            personInfo.neighborhood.length < 1 ||
            personInfo.street.length < 1 ||
            personInfo.number.length < 1 ||
            personInfo.city.length < 1 ||
            personInfo.state.length < 1
          }
          sx={{ width: "100%" }}
        >
          {isLoading ? <CustomLoader /> : <span>Continuar</span>}
        </Button>
      </form>
    </CustomAccordion>
  );
};

export default AddressInfoAccordion;
