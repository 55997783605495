import { FC, useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import ArrowForward from "@mui/icons-material/ArrowForward";

import { usePerson } from "src/shared/contexts/Person";
import { useToast } from "src/shared/contexts/Toast";

import IAccordion from "src/shared/interfaces/accordions";

import CustomAccordion from "src/shared/components/general/Accordion";
import CustomTermsModal from "./modal";
import { useProduct } from "src/shared/contexts/Product";

const TermsInfoAccordion: FC<IAccordion> = ({
  id,
  disabled,
  expanded,
  accordions,
  setAccordions,
  setActiveStep,
}) => {
  const { addToast } = useToast();

  /**Variáveis que mantêm as informações do usuário */
  const { setPersonInfo } = usePerson();

  const { acquiredProducts } = useProduct();

  /**Variável que mantém o status de exibição do modal com os termos e condições*/
  const [isModalOpen, setIsModalOpen] = useState(false);
  /**Variável que mantém o status de aceite dos termos e condições */
  const [areTermsAccepted, setAreTermsAccepted] = useState(false);
  /**Variável que mantém o status de aceite do segundo checkbox */
  const [areTermsAccepted2, setAreTermsAccepted2] = useState(false);

  /**Função responsável por validat e submeter o accordion */
  const handleSubmitAccordion = () => {
    setAccordions((prev) =>
      prev.map((acc) =>
        acc.id === id
          ? {
              ...acc,
              group: 1,
              completed: true,
              disabled: true,
              completedInfo: {
                ...acc.completedInfo,
                button: "",
                content: ["Termos aceitos"],
              },
            }
          : prev.find(
              ({ id: prev_id, disabled }) => prev_id !== id && !disabled
            )
          ? { ...acc }
          : acc.id === id + 1
          ? { ...acc, disabled: false, expanded: true }
          : { ...acc }
      )
    );

    setPersonInfo((prev) => {
      return { ...prev, termsAccepted: true };
    });

    addToast({
      type: "success",
      title: "Termos aceitos",
      description: "Os termos foram aceitos com sucesso",
    });
  };

  /**Efeito que atualiza a variável que mantém o passo atual do usuário, quando o accordion for validado */
  useEffect(() => {
    setActiveStep(accordions!.findIndex(({ disabled }) => !disabled));
  }, [accordions, setActiveStep]);

  useEffect(() => {
    if (
      acquiredProducts &&
      acquiredProducts.length > 0 &&
      acquiredProducts[0].crypted_product_type_id !==
        "eeab68e206a1f93d29eb8903b3b18bf2" &&
      acquiredProducts[0].crypted_product_type_id !==
        "85e42d0162895f044e92f6f25529254d" &&
      acquiredProducts[0].crypted_product_type_id !==
        "7dddb32f6bd23bcddaccb0254dd873f8"
    ) {
      setAreTermsAccepted2(true);
    }
  }, [acquiredProducts]);

  return (
    <CustomAccordion
      id={id}
      title="Termos e Condições"
      disabled={disabled}
      expanded={expanded}
      setAccordions={setAccordions}
    >
      <CustomTermsModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        cryptedProductTypeId={
          acquiredProducts.length > 0 &&
          acquiredProducts[0].crypted_product_type_id
        }
        cryptedProductId={
          acquiredProducts.length > 0 && acquiredProducts[0].crypted_id
        }
      />

      <Typography textAlign="justify">
        Antes de continuar, é importante que você leia e compreenda o Contrato e
        os Termos de Uso. Esses documentos estabelecem os direitos e obrigações
        entre você e nossa empresa, garantindo uma experiência proveitosa para
        todos.
      </Typography>

      <Button
        variant="outlined"
        color="success"
        onClick={() => setIsModalOpen(true)}
        sx={{ margin: "1rem auto 0.25rem auto" }}
      >
        Abrir Termos e Condições
      </Button>

      <FormControlLabel
        control={<Checkbox color="success" />}
        label="Eu declaro que li e aceito os termos e condições"
        checked={areTermsAccepted}
        onChange={() => setAreTermsAccepted((prev) => !prev)}
        sx={{ margin: "0.5rem 0" }}
      />

      {acquiredProducts &&
      acquiredProducts.length > 0 &&
      (acquiredProducts[0].crypted_product_type_id ===
        "eeab68e206a1f93d29eb8903b3b18bf2" ||
        acquiredProducts[0].crypted_product_type_id ===
          "85e42d0162895f044e92f6f25529254d" ||
        acquiredProducts[0].crypted_product_type_id ===
          "7dddb32f6bd23bcddaccb0254dd873f8") ? (
        <FormControlLabel
          control={<Checkbox color="success" />}
          label="Eu declaro que possuo colação de grau anterior a data de realização desta matrícula"
          checked={areTermsAccepted2}
          onChange={() => setAreTermsAccepted2((prev) => !prev)}
          sx={{ margin: "0.5rem 0" }}
        />
      ) : (
        <></>
      )}

      <Button
        variant="contained"
        color="success"
        endIcon={<ArrowForward />}
        disabled={!areTermsAccepted || !areTermsAccepted2}
        onClick={handleSubmitAccordion}
        sx={{ width: "100%" }}
      >
        <span>Continuar</span>
      </Button>
    </CustomAccordion>
  );
};

export default TermsInfoAccordion;
